import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Apollo, gql } from "apollo-angular";
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";
import { Router } from "@angular/router";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userData: any;
  userToken: string;
  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  currentTheme = "default";

  userMenu = [{ title: "Log out" },{ title: "Change Password" }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private apollo: Apollo,
    private router: Router,
    private userService: UserData,
    private layoutService: LayoutService,
    private authService: NbAuthService,
    private breakpointService: NbMediaBreakpointsService
  ) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.userToken = "Bearer " + token["token"]; // here we receive a payload from the token and assigns it to our `user` variable
        this.getPermissions(this.userToken);
        this.getUserData(this.userToken);
      }
    });
    this.menuService.onItemClick().subscribe((event) => {
      this.onContecxtItemSelection(event.item.title);
    });
  }
  onContecxtItemSelection(title) {
    if (title == "Log out" || title == "تسجيل الخروج") {
      localStorage.removeItem("auth_app_token");
      localStorage.removeItem("permissions");
      sessionStorage.removeItem("auth_app_token");
      window.location.reload();
    }
    if(title == "Change Password" || title == "تغيير كلمة المرور"){
      this.router.navigate(['/pages/change-password']);
    }
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => (this.user = users.admin));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  getPermissions(userToken) {
    this.apollo
      .watchQuery({
        query: this.userPermissions,
        context: {
          headers: {
            Authorization: userToken,
          },
        },
      })
      .valueChanges.subscribe(({ data, loading }) => {
        let permissions = data["permission"];
        localStorage.setItem(
          "permissions",
          permissions.map((i) => i.permissionCode)
        );
      });
  }
  getUserData(userToken) {
    this.apollo
      .watchQuery({
        query: this.USER_DATA,
        context: {
          headers: {
            Authorization: userToken,
          },
        },
      })
      .valueChanges.subscribe(({ data, loading }) => {
        this.userData = data["adminUser"];
      });
  }

  userPermissions = gql`
    query {
      permission(isMe: true) {
        permissionCode
        permissionNameEN
        permissionNameAR
        id
      }
    }
  `;
  USER_DATA = gql`
    query GetUser {
      adminUser(isMe: true) {
        id
        username
        roleId
      }
    }
  `;
}
